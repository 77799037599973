import React from "react";
import PropTypes from "prop-types";

const MetricCard = ({
    title,
    value,
    icon
}) => {
    return (
        <>
            <div>{title}</div>
            <div>{value}</div>
            <div>{icon}</div>
        </>
    );
};

MetricCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default MetricCard;
