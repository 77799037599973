import React from 'react';
import MetricCard from '../../components/metricCard/MetricCard';
import styles from './Dashboard.module.css';

export function Dashboard() {
    return (
        <div className={styles.row}>
            <MetricCard title="Net Sales" value="£596" icon="sales" />
            <MetricCard title="Gross Profit" value="£96" icon="gross-profit" />
            <MetricCard title="Profit Margin" value="38%" icon="profit-margin" />
        </div>
    );
}
